<template>
  <div class="container">
    <div class="main">
      <el-image
        class="banner"
        :src="require('@as/event/banner.png')"
      ></el-image>
      <div class="content">
        <div class="left">
          <div class="el-tabs el-tabs--top">
            <div class="el-tabs__header is-top">
              <div class="el-tabs__nav-wrap is-top">
                <div class="el-tabs__nav-scroll">
                  <div
                    role="tablist"
                    class="el-tabs__nav is-top"
                    style="transform: translateX(0px)"
                  >
                    <div
                      class="el-tabs__active-bar is-top"
                      style="width: 64px"
                      :style="{
                        transform: !this.isHot
                          ? 'translateX(0px)'
                          : 'translateX(104px)',
                      }"
                    ></div>
                    <div
                      id="tab-first"
                      role="tab"
                      tabindex="0"
                      class="el-tabs__item is-top"
                      :class="{ 'is-active': !this.isHot }"
                      @click="handleClick(false)"
                    >
                      最新发布
                    </div>
                    <div
                      id="tab-second"
                      role="tab"
                      tabindex="-1"
                      class="el-tabs__item is-top"
                      :class="{ 'is-active': this.isHot }"
                      @click="handleClick(true)"
                    >
                      热门活动
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="el-tabs__content">
              <div
                role="tabpanel"
                id="pane-first"
                aria-labelledby="tab-first"
                class="el-tab-pane"
                style=""
              >
                <div class="content_container">
                  <div class="list">
                    <div
                      class="item"
                      v-for="(item, index) in activeList"
                      :key="index"
                      @click="toDetail(item.id)"
                    >
                      <el-image class="item_img" :src="item.poster"></el-image>
                      <div class="item_title">
                        {{ item.title }}
                      </div>
                      <div class="item_row">
                        <el-image
                          class="item_label_1"
                          :src="require('@as/event/row_icon_1.png')"
                        ></el-image>
                        <div class="item_value">{{ item.province }}</div>
                      </div>
                      <div class="item_row">
                        <el-image
                          class="item_label"
                          :src="require('@as/event/row_icon_2.png')"
                        ></el-image>
                        <div class="item_value">{{ item.startTime }}</div>
                        <div
                          class="item_btn"
                          :class="{ active: index == 1, finish: index == 2 }"
                        >
                          报名中
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pull_more" @click="getAllList" v-if="hasMore">
                    加载更多
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="最新发布" name="first"> </el-tab-pane>
          </el-tabs> -->
          <div class="float">
            <div class="item">
              <div>领域</div>
              <i class="el-icon-arrow-down"></i>
            </div>
            <div class="item active">
              <div>地点</div>
              <i class="el-icon-arrow-down"></i>
            </div>
            <div class="item">
              <div>时间</div>
              <i class="el-icon-arrow-down"></i>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="section">
            <div class="color_title">
              <el-image
                class="title_icon"
                :src="require('@as/serve/tender/right_title.png')"
              ></el-image>
              <div class="title">
                <el-image
                  class="img"
                  :src="require('@as/reading/word.png')"
                ></el-image>
                <div>Say you, Say me</div>
              </div>
              <div class="right_text">共建共享</div>
            </div>
            <div class="section_content">
              <div class="item" @click="toCreate()">
                <el-image
                  class="img"
                  :src="require('@as/reading/wenzhang.png')"
                ></el-image>
                <div class="name">创建活动</div>
              </div>
              <div class="item" @click="toJoin()">
                <el-image
                  class="img"
                  :src="require('@as/reading/zhuanlan.png')"
                ></el-image>
                <div class="name">定制活动</div>
              </div>
            </div>
          </div>
          <div class="section">
            <div class="head">
              <div class="title">
                <el-image
                  class="title_icon"
                  :src="require('@as/event/icon_1.png')"
                ></el-image>
                <div>活动日历</div>
              </div>
              <div class="title_more">2020年至今共计164场</div>
            </div>
            <div class="date_container">
              <div class="date_title">
                <i class="el-icon-caret-left"></i>
                <div class="text">08月 25场活动</div>
                <i class="el-icon-caret-right"></i>
              </div>
              <div class="date_week">
                <div class="item">周日</div>
                <div class="item">周一</div>
                <div class="item">周二</div>
                <div class="item">周三</div>
                <div class="item">周四</div>
                <div class="item">周五</div>
                <div class="item">周六</div>
              </div>
              <div class="date_day">
                <div
                  class="item"
                  :class="{
                    invalid: item == 1,
                    now: item == 2,
                    select: item == 6,
                    not: item == 9,
                  }"
                  v-for="item in 42"
                  v-bind:key="item"
                  @click="popShow = !popShow"
                >
                  <div class="num">
                    {{ item > 31 ? item - 31 : item }}
                  </div>
                  <div class="now_bg" v-if="item == 2"></div>
                  <div class="matter" v-if="item == 4">···</div>
                  <div class="circle" v-if="item == 9">。。。。。。</div>
                </div>
              </div>
              <div class="pop_container" v-if="popShow">
                <div class="pop_item" v-for="item in 3" v-bind:key="item">
                  <div class="pop_item_title">
                    中国进出口银行湖南省分行五凌电力孟加 拉风
                  </div>
                  <div class="pop_item_info">
                    <div class="pop_item_time">2020.08.11</div>
                    <div class="pop_item_address">
                      <el-image
                        class="pop_icon"
                        :src="require('@as/event/icon_2.png')"
                      ></el-image>
                      <div>北京</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section">
            <div class="head">
              <div class="title">
                <el-image
                  class="title_icon"
                  :src="require('@as/event/icon_3.png')"
                ></el-image>
                <div>品牌活动</div>
              </div>
              <div class="title_more">MORE</div>
            </div>
            <div class="plan">
              <el-image
                class="plan_img"
                :src="require('@as/reading/wenzhang.png')"
              ></el-image>
              <el-timeline>
                <el-timeline-item
                  :timestamp="2018 + '/' + 4 + '/' + item"
                  :color="item == 1 ? '#1C9AB1' : ''"
                  placement="top"
                  v-for="item in 4"
                  v-bind:key="item"
                >
                  <div class="info">
                    <div class="info_title" :class="{ active: item == 1 }">
                      中国进出口银行湖南省分行五凌电力孟 加拉风电法律服务项目
                    </div>
                    <div class="info_subtitle">
                      <el-image
                        class="info_icon"
                        :src="require('@as/event/icon_2.png')"
                      ></el-image>
                      <div>北京</div>
                    </div>
                  </div>
                </el-timeline-item>
              </el-timeline>
            </div>
          </div>
          <div class="section">
            <div class="head">
              <div class="title">
                <el-image
                  class="title_icon"
                  :src="require('@as/event/icon_6.png')"
                ></el-image>
                <div>热门主办方</div>
              </div>
              <div class="title_more">MORE</div>
            </div>
            <div class="hot">
              <div class="card" v-for="item in 6" v-bind:key="item">
                <el-image
                  class="card_img"
                  :src="require('@as/event/icon_3.png')"
                ></el-image>
                <div class="card_title">中伦律师事务所</div>
              </div>
            </div>
          </div>
          <div class="section">
            <div class="head">
              <div class="title">
                <el-image
                  class="title_icon"
                  :src="require('@as/event/icon_4.png')"
                ></el-image>
                <div>相关咨询</div>
              </div>
              <div class="title_more">MORE</div>
            </div>
            <div class="refer">
              <div class="row last" v-for="item in 3" v-bind:key="item">
                <el-image
                  class="img"
                  :src="require('@as/serve/knowledge/banner.png')"
                ></el-image>
                <div class="info">
                  <div class="title">媒体合作咨询</div>
                  <div class="subtitle">姓名：陈俊</div>
                  <div class="subtitle">电话：2485</div>
                  <div class="subtitle">邮箱：2485@163.com</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="take">
      <div class="text">最新活动 尽在掌握</div>
      <el-input v-model="input" placeholder="请输入内容"></el-input>
      <div class="take_btn">立即订阅</div>
      <el-image
        class="take_icon"
        :src="require('@as/event/icon_5.png')"
      ></el-image>
    </div>
  </div>
</template>

<script>
import { Activity } from '@api'
export default {
  data() {
    return {
      isHot: false,
      input: '',
      popShow: false,
      activeList: [],
      page: 1,
      hasMore: true,
    }
  },
  methods: {
    handleClick(isHot) {
      this.isHot = isHot
      this.page = 1
      this.hasMore = true
      this.getAllList()
      this.activeList = []
    },
    toDetail() {
      this.$router.push('eventDetail')
    },
    toCreate() {
      this.$router.push('eventCreate')
    },
    toJoin() {
      this.$router.push('eventJoin')
    },
    getAllList() {
      Activity.getList({
        area: this.area,
        isHot: this.isHot,
        page: this.page,
        pageSize: 18,
        time: this.time,
      }).then((res) => {
        this.activeList = this.activeList.concat(res)
        this.page += 1
      })
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  background: #f5f7f8;
}
.banner {
  width: 1200px;
  height: 345px;
}
.main {
  margin: 20px auto;
  width: 1200px;

  .content {
    margin-top: 20px;
    display: flex;
    .left {
      position: relative;
      flex: 1;
      & /deep/ .el-tabs__header {
        margin: 0 0 20px;
      }
      & /deep/ .el-tabs__nav-wrap {
        padding: 17px 26px 0;
        background: #fff;
      }
      & /deep/ .el-tabs__item {
        padding-bottom: 17px;
        height: auto;
        line-height: 1;
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
      & /deep/ .el-tabs__item.is-active {
        color: #1c99b0;
      }
      & /deep/ .el-tabs__active-bar {
        background: #1c99b0;
      }
      .float {
        position: absolute;
        top: 18px;
        right: 20px;
        display: flex;
        .item {
          margin-left: 40px;
          display: flex;
          align-items: center;
          font-size: 13px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #595757;
          i {
            margin-left: 8px;
          }
          &.active {
            color: #1c9ab1;
          }
        }
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item {
          margin-bottom: 20px;
          padding-bottom: 30px;
          width: 269px;
          box-sizing: border-box;
          background: #fff;
          cursor: pointer;

          &:hover {
            box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.2);
            .item_title {
              color: #1c9ab1;
            }
          }
          .item_img {
            width: 269px;
            height: 130px;
          }
          .item_title {
            padding: 20px;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #595757;
            line-height: 26px;
          }

          .item_row {
            margin-top: 16px;
            padding: 0 20px;
            display: flex;
            align-items: flex-end;
            &:first-child {
              margin-top: 44px;
            }
            .item_label_1 {
              margin-right: 3px;
              width: 14px;
              height: 20px;
            }
            .item_label {
              width: 18px;
              height: 18px;
            }
            .item_value {
              margin-left: 14px;
              margin-bottom: 4px;
              font-size: 12px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #808080;
            }
            .item_btn {
              margin-left: auto;
              padding: 7px 17px;
              border-radius: 4px;
              font-size: 12px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #ffffff;
              background: #1c9ab1;

              &.active {
                background: #f08d1d;
              }
              &.finish {
                background: #939494;
              }
            }
          }
        }
      }
      .pull_more {
        margin-top: 23px;
        height: 40px;
        line-height: 40px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
        text-align: center;
        background: #ebecec;
        cursor: pointer;
      }
    }
    .right {
      margin-left: 20px;
      width: 330px;
      .section {
        margin-bottom: 20px;
        padding-bottom: 20px;
        background: #fff;
        &:not(:first-child) {
          padding: 25px 23px;
        }
        .color_title {
          padding: 8px 10px;
          display: flex;
          align-items: center;
          background: linear-gradient(90deg, #2f9b9d, #0f99be);
          .title_icon {
            width: 33px;
            height: 33px;
            border-radius: 50%;
          }
          .title {
            margin-left: 10px;
            font-size: 10px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #ffffff;
            .img {
              width: 42px;
              height: 14px;
            }
          }
          .right_text {
            margin-left: auto;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 500;
            color: #ffffff;
          }
        }
        .section_content {
          margin: 20px 14px 0;
          padding: 15px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #dedede;
          box-sizing: border-box;
          .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1;
            height: 80px;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
            &:first-child {
              border-right: 1px solid #dedede;
            }
            .img {
              margin-bottom: 15px;
              width: 36px;
              height: 30px;
            }
          }
        }
        .head {
          display: flex;
          justify-content: space-between;
          .title {
            padding-bottom: 6px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #595757;
            border-bottom: 2px solid #4a97ad;
            .title_icon {
              margin-right: 10px;
            }
          }
          .title_more {
            padding-bottom: 6px;
            flex: 1;
            font-size: 10px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #a6a7a7;
            text-align: right;
            border-bottom: 1px solid #dedede;
            cursor: pointer;
          }
        }
        .plan {
          margin-top: 22px;
          .plan_img {
            margin-bottom: 20px;
            width: 280px;
            height: 114px;
          }
          & /deep/ .el-timeline-item__timestamp.is-top {
            position: absolute;
            left: -80px;
            top: 0;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #afafaf;
          }
          & /deep/ .el-timeline {
            padding-left: 80px;
          }
          & /deep/ .el-timeline-item {
            &:last-child {
              padding-bottom: 0;
            }
          }
          & /deep/ .el-timeline-item__wrapper {
            padding-left: 22px;
          }
          & /deep/ .el-timeline-item__tail {
            top: 4px;
            border-left-width: 1px;
          }
          & /deep/ .el-timeline-item__node--normal {
            top: 4px;
            width: 5px;
            height: 5px;
            left: 2.5px;
          }
          & /deep/ .el-timeline-item__content:hover {
            cursor: pointer;
          }
          .info {
            .info_title {
              font-size: 13px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #595757;
              line-height: 18px;
              &.active {
                color: #1c9ab1;
              }
            }
            .info_subtitle {
              margin-top: 15px;
              display: flex;
              align-items: center;
              font-size: 12px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #afafaf;
              .info_icon {
                margin-right: 5px;
                width: 9px;
                height: 11px;
              }
            }
          }
        }
        .hot {
          display: flex;
          flex-wrap: wrap;
          margin-top: 26px;
          .card {
            margin-bottom: 28px;
            width: 50%;
            text-align: center;
            cursor: pointer;
            &:hover {
              .card_title {
                color: #1c9ab1;
              }
            }
            &:nth-last-child(-n + 2) {
              margin-bottom: 10px;
            }
            .card_img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
            }
            .card_title {
              margin-top: 9px;
              font-size: 14px;
              font-family: PingFangSC;
              font-weight: 500;
              color: #595757;
            }
          }
        }
        .refer {
          .row {
            padding: 17px 6px;
            display: flex;
            align-items: flex-start;
            &:hover {
              cursor: pointer;
              background: #ecf2f6;
            }
            &:not(:last-child) {
              border-bottom: 1px solid #dedede;
            }
            .img {
              margin-right: 15px;
              flex-shrink: 0;
              width: 42px;
              height: 42px;
              border-radius: 50%;
            }
            .info {
              .name {
                font-size: 14px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #666666;
              }

              .title {
                margin: 10px 0 12px;
                font-size: 15px;
                font-family: PingFangSC;
                font-weight: 500;
                color: #595757;
              }
              .subtitle {
                margin-top: 10px;
                font-size: 13px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #a7a8a8;
              }
            }
          }
        }
      }
      .date_container {
        position: relative;
        margin-top: 15px;
        border: 1px solid #c6e6eb;
        .date_title {
          padding: 14px 11px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #fff;
          background: #1c9ab1;
          border-bottom: 1px solid #1c9ab1;
          .text {
            flex: 1;
            text-align: center;
          }
        }
        .date_week {
          display: flex;
          align-items: center;
          .item {
            width: 40.29px;
            height: 28px;
            font-size: 10px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #808080;
            line-height: 28px;
            text-align: center;
            background: #f5f7f8;
            &:first-child {
              color: #babbbb;
            }
            &:last-child {
              color: #babbbb;
            }
          }
        }
        .date_day {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          .item {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 40.2px;
            height: 40px;
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #808080;
            text-align: center;
            &:nth-child(7n),
            &:nth-child(7n + 1) {
              background: #f5f7f8;
            }
            &.invalid {
              background: #fff;
            }
            .now_bg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background: #1c9ab1;
              z-index: 1;
            }
            &.now {
              .num {
                color: #fff;
                display: flex;
                z-index: 2;
              }
            }
            &.select {
              color: #fff;
              background: #1c9ab1;
              .matter {
                color: #fff;
              }
            }
            &.not {
              color: #1c9ab1;
            }
            .matter {
              position: absolute;
              left: 50%;
              bottom: 3px;
              transform: translateX(-50%);
              letter-spacing: 2px;
              color: #808080;
            }
            .circle {
              position: absolute;
              left: 50%;
              bottom: 3px;
              letter-spacing: -8px;
              transform: translateX(-50%);
              color: #1c9ab1;
            }
          }
        }
        .pop_container {
          position: absolute;
          top: 108px;
          left: 0;
          padding: 10px;
          width: 238px;
          box-sizing: border-box;
          background: #fff;
          box-shadow: 2px 1px 4px 0px rgba(0, 0, 0, 0.3);
          .pop_item {
            margin-bottom: 10px;
            &:not(:first-child) {
              padding-top: 8px;
              border-top: 1px solid #e7e7e7;
            }
            &:last-child {
              margin: 0;
            }
            .pop_item_title {
              font-size: 12px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #808080;
              line-height: 16px;
            }
            .pop_item_info {
              margin-top: 6px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .pop_item_time {
                font-size: 10px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #a6a7a7;
              }
              .pop_item_address {
                display: flex;
                align-items: center;

                font-size: 10px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #bbbcbc;
                .pop_icon {
                  margin-right: 3px;
                  width: 10px;
                  height: 11px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.take {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eaebeb;
  box-sizing: border-box;
  .text {
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #595757;
  }
  .take_btn {
    margin-left: -4px;
    z-index: 2;
    padding: 7px 12px;
    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
    border-radius: 0 3px 3px 0;
    background: #1c9ab1;

    cursor: pointer;
  }
  .take_icon {
    margin-left: 56px;
    width: 11px;
    height: 11px;
    cursor: pointer;
  }
  & /deep/ .el-input {
    margin-left: 42px;
    width: 220px;
  }
  & /deep/ .el-input__inner {
    height: 26px;
  }
}
</style>